import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://sued/<br />10,000.00 HNS<br />0.03 ETH<br />$115.94<br /><br />🥈 https://lays/<br />5,000.99 HNS<br />0.02 ETH<br />$57.98<br /><br />🥉 https://trad/<br />5,000.00 HNS<br />0.02 ETH<br />$57.97<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://xn--qih/ 👉 https://⌧/<br />1,000.00 HNS<br />0.00 ETH<br />$11.59<br /><br />🥈 https://xn--vcd/ 👉 https://༶/<br />1,000.00 HNS<br />0.00 ETH<br />$11.59<br /><br />🥉 https://metaverserace/<br />1,000.00 HNS<br />0.00 ETH<br />$11.59<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▍▁▁▁▁▁▁▁▁▁▁▁ ] 31.75%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▉▏▁▁▁▁▁▁▁ ] 53.9%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6542)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)