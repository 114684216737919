import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://openweb/<br />1,000.00 HNS<br />0.00 ETH<br />$13.64<br /><br />🥈 https://dmap/<br />500.00 HNS<br />0.00 ETH<br />$6.82<br /><br />🥉 https://jaihoz/<br />222.99 HNS<br />0.00 ETH<br />$3.04<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://dirt/<br />11,110.00 HNS<br />0.05 ETH<br />$151.62<br /><br />🥈 https://cryptotrapper/<br />1,500.00 HNS<br />0.01 ETH<br />$20.47<br /><br />🥉 https://cryptoceo/<br />1,495.00 HNS<br />0.01 ETH<br />$20.40<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▍▁▁▁▁▁▁▁▁▁▁▁ ] 31.75%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▉▏▁▁▁▁▁▁▁ ] 54.0%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6542)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)